import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import ComponentLayout from '@myaccount/ComponentLayout';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { StarberryIcons } from '@myaccount/icons';

import defaults from './menuListIconsTabsDefaults';
import clsx from "clsx"

const MenuListIcons = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        _data,
        data,
        manageValue,
        manageHandleChange,
        tabsProps,
        tabProps,
        btnIconProps,
        // tabItems,
        addUrl,
    } = props;

    const tabData = data ? data : _data;

    let icon = null;

    const manageHandleChangeWrap = (event, newValue) => {
        manageHandleChange(event, newValue)
        if (addUrl) {
            let url = `${window.location.pathname}#${newValue}`;
            window.history.pushState({}, '', url)
        }
    }

    return (
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Tabs
                value={manageValue}
                onChange={manageHandleChangeWrap}
                {...tabsProps}
                classes={{
                    root: "menuListIconsTabs",
                    vertical: "menuListIconsTabs-vertical",
                    indicator: "menuListIconsTabs-indicator"
                }}
            >
                {tabData.map(tab => {
                    icon = <StarberryIcons iconName={tab.iconName} {...btnIconProps} />;
                    return(
                        <Tab
                            key={tab.id}
                            value={tab.id}
                            label={tab.name}
                            icon={icon}
                            classes={{
                                root: clsx("menuListIconsTabs-tab", "menuListIconsTabs-tab-wrapper"),
                                labelIcon: "menuListIconsTabs-tab-labelIcon",
                            }}
                            {...tabProps}
                        />
                    )
                })}
            </Tabs>
        </ComponentLayout>
    )
}, 'menuListIconsTabs', defaults)

export default MenuListIcons
