import React from 'react';
import { usePropertyActionState, useAuthState } from "@myaccountServices";
import NoActions from "@myaccount/progress/NoActions";
// import { Flexbox } from '@starberryComponentsMui';
import Actions from '@myaccount/actions';
import ActionsSkeleton from "../../../../actions/actionsSkeleton"
// import Calendar from '@myaccount/calendar';
// import { /* calendarDate, */getMonth } from '@myaccount/helper/dateformat';


// Calendar Action Container wrap for enable loading
const AppointmentsContent = (props) => {

    const { state, services } = usePropertyActionState();
    const { services:authServices } = useAuthState();
    const data = state?.propertyAppointments || [];
    const noRecord = (!state.loading && !data.length);
    const propertyImage = props.property.property_detail?.thumbnail;

    const fetchData = () => {
        services.getPropertyAppointments({property_id:props.property.property_detail.crm_id});
    }

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading && !data.length) {
        return <ActionsSkeleton title />
    }

    return (
        <>
            {noRecord && (
                <NoActions
                    title="There are no appointments"
                    message="At the moment all the appointments are closed..."
                    icon="appointmentsIcon"
                />
            )}
            {!noRecord && (
                <Actions
                    title={`Appointment`}
                    data={data}
                    property={props.property.property_detail}
                    reloadDataFun={reloadDataFun}
                    image={propertyImage}
                />
            )}
        </>
    )
}

export default React.memo(AppointmentsContent);
