import * as React from "react"
import Actions from "@myaccount/actions"
import NoActions from "@myaccount/progress/NoActions"
import ComponentLayout from '@myaccount/ComponentLayout'

const OnlyActions = (props) => {
    const {
        noActionTitle,
        noActionMessage,
        noActionIcon,
        property,
        data,
        ...rest
    } = props

    const actionData = data || [];
    const propertyImage = property?.thumbnail;

    if (!actionData.length) {
        return (
            <NoActions
                title={noActionTitle ? noActionTitle : "There are no pending actions"}
                // message={noActionMessage ? noActionMessage : "At the moment all the actions are closed..."}
                icon={noActionIcon ? noActionIcon : "noPendingActions"}
            />
        )
    }

    return (
        <>
            <Actions {...rest} data={actionData} image={propertyImage} />
        </>
    )
}

const PendingActionsInitContainer = (props) => {
    const {
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        data,
        ...rest
    } = props

    const actionData = data || [];
    const hasData = !!data.length;

    return (
        <ComponentLayout
            title={title}
            moreLabel={hasData ? moreLabel : ''}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <OnlyActions {...rest} data={actionData} />
        </ComponentLayout>
    )
}

export default React.memo(PendingActionsInitContainer)
