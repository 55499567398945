import React from 'react';
import { get } from 'lodash';
import { usePropertyActionState, useAuthState } from "@myaccountServices";
import NoActions from "@myaccount/progress/NoActions";
import Actions from '@myaccount/actions';
import CreateTenancy from '@myaccount/PropertyResult/CreateTenancy';
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"

const TenancyContent = (props) => {

    const { state, services } = usePropertyActionState();
    const { services:authServices } = useAuthState();
    const offerData = state?.propertyTenances || [];
    const noRecord = (!state.loading && !offerData.length);
    const propertyImage = props.property.property_detail?.thumbnail;
    const showOfferBtn = get(props, `${props.role || ''}.tabOfferOfferBtn`, false);
    const showAddIcon = get(props, `${props.role || ''}.showAddIcon`, false);

    const fetchData = () => {
        services.getPropertyTenancies({property_id:props.property.property_detail.crm_id});
    }

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return <ActionsSkeletonWithMore title />
    }

    return (
        <>
            {showOfferBtn && (
                <CreateTenancy showAddIcon={showAddIcon} property={props.property.property_detail} reloadDataFun={reloadDataFun} />
            )}
            {noRecord ? (
                <NoActions
                    title="There are no Tenancies"
                    icon="tenancyIcon"
                />
            ) : (
                <Actions
                    title="Tenancies"
                    data={offerData}
                    reloadDataFun={reloadDataFun}
                    image={propertyImage}
                    // showActionButton={false}
                />
            )}
        </>
    )
}

export default TenancyContent;
