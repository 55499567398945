import React from 'react';
import { get } from 'lodash';
import { usePropertyActionState, useAuthState } from "@myaccountServices";
import NoActions from "@myaccount/progress/NoActions";
import Actions from '@myaccount/actions';
import MakeOfferBtn from '@myaccount/property/components/makeOffer';
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"

const OfferContent = (props) => {
    const { state, services } = usePropertyActionState();
    const { services:authServices } = useAuthState();
    const offerData = state?.propertyOffers || [];
    const noRecord = (!state.loading && !offerData.length);
    const propertyImage = props.property.property_detail?.thumbnail;
    const actionTitle = props?.offer?.actionTitle || 'Offers';
    const showOfferBtn = get(props, `${props.role || ''}.tabOfferOfferBtn`, false);
    const showAddIcon = get(props, `${props.role || ''}.showAddIcon`, false);

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = () => {
        services.getPropertyOffers({property_id:props.property.property_detail.crm_id});
    }

    React.useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading && !offerData.length) {
        return <ActionsSkeletonWithMore title />;
    }

    return (
        <>
            {showOfferBtn && (
                <MakeOfferBtn addIcon={true} showAddIcon={showAddIcon} property={props.property.property_detail} reloadDataFun={reloadDataFun} />
            )}
           {noRecord ? (
                <NoActions
                    title={`There are no ${actionTitle}`}
                    icon="offerIcon"
                />
            ) : (
                <Actions
                    title={actionTitle}
                    data={offerData}
                    reloadDataFun={reloadDataFun}
                    image={propertyImage}
                />
           )}
        </>
        
    )
}

export default OfferContent;
