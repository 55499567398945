import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/newDocument";
import { trackNewDocument, getUserRole } from "@myaccount/helper/eventTracking";

import defaults from "./dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {

    const { className } = props
    const property = props?.property || {}
    const property_detail = props?.property.property_detail || {}

    let associated_id =  property_detail.crm_id, associated_type = 'property';
    if (property.owner_type === 'lettings' && property.current_tenancy_id) { // propertyid / tenancy id
        associated_id = property.current_tenancy_id;
        associated_type = 'tenancy';
    }/* else if (property.is_owner) { // propertyid / tenancy id
        associated_id = property_detail.crm_id;
        associated_type = 'property';
    }*/

    // const associatedtype - property / tenancy

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = async(event) => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            const params = {
                form_name: "new_document",
                property_id: property_detail.crm_id,
                is_owner: property.is_owner,
                associatedid: associated_id,
                associatedtype: associated_type,
                successMessage: "Document Posted Successfully",
                multipart: true,
                trackData: () => {
                    trackNewDocument({
                        eventLabel: `Property - ${property_detail?.crm_id}`,
                        eventRole: getUserRole(property_detail)
                    })
                }
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
