/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/

import { useTheme } from "@starberryComponentsMui"
import {
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_MYPROPERTY_SELLING,
    MYACCOUNT_MYPROPERTY_BUYING,
    MYACCOUNT_MYPROPERTY_RENTING,
    MYACCOUNT_MYPROPERTY_LETTING,
    MYACCOUNT_LANDLAND,
} from "@myaccountUrls";

import PrivateRoute from "@myaccount/hoc/PrivateRoute"
import MyProperty from "@myaccount/pages/my-property"
import MyPropertySeller from "@myaccount/pages/my-property/seller"
import MyPropertyTenant from "@myaccount/pages/my-property/tenant"
import MyPropertyBuyer from "@myaccount/pages/my-property/buyer"
import MyPropertyLandlord from "@myaccount/pages/my-property/landlord"
import _myAccount from "@myaccountSampleData/myAccount.yaml"
import PageHeaderHelmet from "@components/pageHeader";

const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY}
                    component={MyProperty}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_SELLING}
                    component={MyPropertySeller}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_BUYING}
                    component={MyPropertyBuyer}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_RENTING}
                    component={MyPropertyTenant}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_LETTING}
                    component={MyPropertyLandlord}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_LANDLAND}
                    component={MyPropertyLandlord}
                    myAccount={myAccount}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages
