import React from 'react';
import { isEmpty } from "lodash";
import CurrentTenancy from '@myaccount/currentTenancy';
import PendingItems from '@myaccount/pendingItems';
import ActionsWithLayout from '@myaccount/pendingActions/withGlobalLayout';
import { formatPrice } from '@myaccount/helper/action';
import { displayData } from '@myaccount/helper/dateformat';

const CheckInComp = (props) => {
    const progressionData = props?.data || [];
    return (
        <PendingItems
            data={progressionData}
            title="Check in Progress"
        />
    )
}
const CheckOutComp = (props) => {
    const progressionData = props?.data || [];
    return (
        <PendingItems
            data={progressionData}
            title="Check out Progress"
        />
    )
}
const UtilitiesComp = (props) => {

    let data = props?.data || [];

    if (!data.length)
        return <></>

    return (
        <ActionsWithLayout
            title="Utilities"
            // moreLabel="View all"
            noActionTitle="There are no utilities"
            noActionMessage="At the moment all the utilities are closed..."
            property={props?.property}
            data={data}
        />
    )
}
const CurrentTenancyComp = (props) => {

    if (isEmpty(props.property?.current_tenancy))
        return <></>

    const tenancyData = props.property?.current_tenancy || [];
    const utilities = tenancyData?.utilities || []
    let currentData = [
        {
            "id": "2",
            "title": "Rent:",
            "value": `${formatPrice(tenancyData?.rent)} ${tenancyData?.rent_frequency}`
        },
    ]

    if (tenancyData?.start_date && tenancyData?.end_date) {
        currentData.push({
            "id": "3",
            "title": "Duration:",
            "value": `${displayData(tenancyData?.start_date)} - ${displayData(tenancyData?.end_date)}`
        })
    }

    if (props.property.is_owner) {
        currentData.unshift(
            {
                "id": "1",
                "title": "Tenant:",
                "value": tenancyData?.applicant_name
            }
        )
    }
    return (
        <>
            <CurrentTenancy
                title="Current Tenancy"
                data={currentData}
            />
            <UtilitiesComp property={props.property.property_detail} data={utilities} />
        </>
    )
}
const ArrangingTenancyComp = (props) => {

    if (isEmpty(props.property?.arranging_tenancy))
        return <></>

    const tenancyData = props.property?.arranging_tenancy || [];
    const utilities = tenancyData?.utilities || []
    const check_in = tenancyData?.check_in || []
    const currentData = [
        {
            "id": "2",
            "title": "Rent:",
            "value": `${formatPrice(tenancyData?.rent)} ${tenancyData?.rent_frequency}`
        }
    ]
    if (tenancyData?.start_date && tenancyData?.end_date) {
        currentData.push({
            "id": "3",
            "title": "Duration:",
            "value": `${displayData(tenancyData?.start_date)} - ${displayData(tenancyData?.end_date)}`
        })
    }
    if (props.property.is_owner) {
        currentData.unshift(
            {
                "id": "1",
                "title": "Tenant:",
                "value": tenancyData?.applicant_name
            }
        )
    }
    return (
        <>
            <CurrentTenancy
                title="Arranging Tenancy"
                data={currentData}
            />
            <UtilitiesComp property={props.property.property_detail} data={utilities} />
            <CheckInComp
                data={check_in}
            />
        </>
    )
}
const CompletedTenancyComp = (props) => {

    if (isEmpty(props.property?.completed_tenancy))
        return <></>

    const tenancyData = props.property?.completed_tenancy || [];
    const utilities = tenancyData?.utilities || []
    const check_out = tenancyData?.check_out || []
    const currentData = [
        {
            "id": "2",
            "title": "Rent:",
            "value": `${formatPrice(tenancyData?.rent)} ${tenancyData?.rent_frequency}`
        }
    ]
    if (tenancyData?.start_date && tenancyData?.end_date) {
        currentData.push({
            "id": "3",
            "title": "Duration:",
            "value": `${displayData(tenancyData?.start_date)} - ${displayData(tenancyData?.end_date)}`
        })
    }
    if (props.property.is_owner) {
        currentData.unshift(
            {
                "id": "1",
                "title": "Tenant:",
                "value": tenancyData?.applicant_name
            }
        )
    }
    return (
        <>
            <CurrentTenancy
                title="Completed Tenancy"
                data={currentData}
            />
            <UtilitiesComp property={props.property.property_detail} data={utilities} />
            <CheckOutComp
                data={check_out}
            />
        </>
    )
}

const TenancyOverview = (props) => {
    return (
        <>
            <CurrentTenancyComp {...props} />
            <ArrangingTenancyComp {...props} />
            <CompletedTenancyComp {...props} />
        </>
    )
}

export default TenancyOverview;
