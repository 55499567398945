export default {
    "props": {
        "maxDialogW": "sm",
        "btnLabelProps": "Contact Us",
        "btnProps": {
            "color": "primary",
            "variant": "contained"
        }
    }
}
