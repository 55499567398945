import * as React from 'react';
import { get } from 'lodash';
import Grid from '@mui/material/Grid';
import { withSubtheme } from '@starberryComponentsMui';
import LineChart from '@myaccount/charts/lineChart';
import FormSelectField from "@myaccount/forms/SelectField"
import FormDateField from "@myaccount/forms/FormDateField"
import { dateFieldFormat } from '@myaccount/helper/dateformat';
import CircularProgress from "@myaccount/progress/CircularProgress";

import defaults from './defaults';

const PerformanceChart = withSubtheme((props) => {
    const {
        className,
        setDateFilters,
        dateFilters,
        state
    } = props

    const options = props?.data?.options || [];
    const items = props?.data?.items || [];
    const [reportType, setReportType] = React.useState(props?.data?.default_item || '')

    const handleChangeFromDate = (val) => {
        setDateFilters({...dateFilters, fromDate:dateFieldFormat(val)})
    }
    const handleChangeToDate = (val) => {
        setDateFilters({...dateFilters, toDate:dateFieldFormat(val)})
    }

    const renderData = () => {
        if (state.loading) {
            return <CircularProgress />
        } else if ( items.length ) {
            return <LineChart data={items} dataKey={reportType} />
        } else {
            return <>Sorry, no data available.</>
        }
    }

    return(
        <div className={className}>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormSelectField
                            name="report-type"
                            labelId="reportType"
                            id="demo-simple-select"
                            variant="outlined"
                            key={0}
                            className="globalForm-selectField" // I'm using className as this is applied to the correct parent div of select box
                            type="select"
                            label= "Select Report Type"
                            displayEmpty = {true}
                            fullWidth
                            defaultValue={reportType}
                            values = {options}
                            onChange={(event) => { setReportType(event.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormDateField
                            key={1}
                            classes={{root: "globalForm-textField"}}
                            fullWidth
                            type="date"
                            label="From Date"
                            name="report_from"
                            id="report-from"
                            showPastDate={true}
                            handleOnChange={handleChangeFromDate}
                            inputProps={{defaultValue:dateFilters.fromDate}}
                            minDate={get(dateFilters, 'maxFromDate', new Date())}
                            maxDate={get(dateFilters, 'maxToDate', new Date())}
                            disableHighlightToday={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormDateField
                            key={1}
                            classes={{root: "globalForm-textField"}}
                            fullWidth
                            type="date"
                            label="To Date"
                            name="report_to"
                            id="report-to"
                            showPastDate={true}
                            handleOnChange={handleChangeToDate}
                            inputProps={{defaultValue:dateFilters.toDate}}
                            minDate={get(dateFilters, 'maxFromDate', new Date())}
                            maxDate={get(dateFilters, 'maxToDate', new Date())}
                            disableHighlightToday={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {renderData()}
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}, "performanceChart", defaults);

export default React.memo(PerformanceChart);
