import * as React from 'react';
import { get } from 'lodash';
import Typography from '@mui/material/Typography';
import { withSubtheme } from '@starberryComponentsMui';
import PerformanceChart from '../performanceChart';
import defaults from './defaults';

const DefaultPerformance = withSubtheme((props) => {
    const {
        className,
        titleProps,
        _dateFilters,
        setDateFilters,
        state,
        reportType,
        reportTypeText,
    } = props

    const data = get(state, `propertyStats.${reportType}`,  ``)
    let dateFilters = _dateFilters;
    if (get(state, `propertyStats.${reportType}.start_date`, ``)) {
        dateFilters.maxFromDate = get(state, `propertyStats.${reportType}.start_date`, ``)
        if ((new Date(dateFilters.fromDate) <= new Date(dateFilters.maxFromDate)) || (new Date(dateFilters.fromDate) >= new Date(dateFilters.maxToDate)))
            dateFilters.fromDate = dateFilters.maxFromDate;
    }
    if (get(state, `propertyStats.${reportType}.end_date`, ``)) {
        dateFilters.maxToDate = get(state, `propertyStats.${reportType}.end_date`, ``)
        if ((new Date(dateFilters.toDate) >= new Date(dateFilters.maxToDate)) || (new Date(dateFilters.toDate) <= new Date(dateFilters.maxFromDate)))
            dateFilters.toDate = dateFilters.maxToDate;
    }

    return(
        <div className={className}>
            <Typography {...titleProps} classes={{root: "performance-title"}}>Report for {reportTypeText} Performance</Typography>
            <PerformanceChart data={data} setDateFilters={setDateFilters} dateFilters={dateFilters} state={state} />
        </div>
    )
}, "facebookPerformance", defaults);

export default React.memo(DefaultPerformance);
