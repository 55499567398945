import * as React from 'react';
import { isEmpty } from "lodash";
import { withSubtheme } from '@starberryComponentsMui';

import ComponentLayout from '@myaccount/ComponentLayout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import defaults from './defaults';

const CurrentTenancy = withSubtheme(props => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,

        tenantContainerProps,
        tenantTextTitleProps,
        tenantTextStatusProps,
        data,
        _data
    } = props;

    let currentData = data ? data : _data;

    if (isEmpty(currentData))
        return <></>

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            {currentData.map(item => (
                <Box key={item.id} sx={tenantContainerProps}>
                    <Typography {...tenantTextTitleProps} classes={{root: "tenantTextTitle"}}>{item.title}</Typography>
                    <Typography {...tenantTextStatusProps} classes={{root: "tenantTextStatus"}}>{item.value}</Typography>
                </Box>
            ))}
        </ComponentLayout>
    )
}, 'currentTenancy', defaults)

export default CurrentTenancy;
