import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import defaults from './defaults';
import { FormStoreProvider, useFormState } from "@myaccountServices";
import CircularProgress from '@myaccount/progress/CircularProgress'

const ContactBtnWrap = withSubtheme(props => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        btnProps,
        property,
        maxDialogW
        // link,
    } = props

    const label = btnLabel ? btnLabel : btnLabelProps;

    const { state, services } = useFormState()

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose} property={property}
        />
    }

    return(
        <>
            <Button {...btnProps} className="btn contact-btn" onClick={handleClickOpen}>{label}</Button>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                property={property}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={modalTitle ? modalTitle : "Contact Us"}
                maxDialogWidth={maxDialogW}
            />
        </>
    )
}, 'contactBtn', defaults)

const ContactBtn = (props) => {
    return(
        <FormStoreProvider>
            <ContactBtnWrap
                {...props}
            />
        </FormStoreProvider>
    )
}


export default ContactBtn;
