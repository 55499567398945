import * as React from "react"
import Activities from "@myaccount/activities"
import NoActions from "@myaccount/progress/NoActions"
import { usePropertyActionState } from "@myaccountServices";
import ComponentLayout from '@myaccount/ComponentLayout'
import Alert from "@myaccount/alert"
import RecentActivitiesSkeleton from "./recentActivitiesSkeleton"

const RecentActivityDetails = props => {

    const {
        componentLayout,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        property,
        noActionTitle,
        noActionIcon,
        // data,
        // ...rest
    } = props

    const { state, services } = usePropertyActionState();

    const data = state?.recentActivities || [];
    const noRecord = (!state.loading && !data.length);

    React.useEffect(() => {
        services.getRecentActions({property_id:property.property_detail.crm_id});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return <RecentActivitiesSkeleton />;
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    if (noRecord) {
        return (
            <NoActions
                title={noActionTitle ? noActionTitle : "There are no recent activities"}
                icon={noActionIcon ? noActionIcon : "recentActivitiesIcon"}
            />
        )
    }


    if (componentLayout) {
        const hasData = !!data.length;
        return (
            <ComponentLayout
                title={title}
                moreLabel={hasData ? moreLabel : ''}
                moreLinkUrl={moreLinkUrl}
                titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            >
                <Activities data={data} />
            </ComponentLayout>
        )
    } else {
        return (
            <>
                <Activities data={data} />
            </>
        )
    }
}

export default React.memo(RecentActivityDetails)
