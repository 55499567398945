import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import Box from "@mui/material/Box";
import defaults from './defaults';
import { Helmet } from "react-helmet";
import clsx from 'clsx';

const FloatingContainer = withSubtheme((props) => {
    const {
        className,
        children,
    } = props

    return(
        <>
            <Helmet
                bodyAttributes={{
                    class: 'floatingContainer-toolbar'
                }}
            />
            <div className={clsx(children.length >= 3 ? "floatingContainer-dense" : null, className)}>
                <Box className={"floatingContainer"}>{children}</Box>
            </div>
        </>
    )
}, 'floatingContainer', defaults)


export default FloatingContainer;
