import React from 'react';
import { usePropertyActionState } from "@myaccountServices";
import ComponentLayout from '@myaccount/ComponentLayout';
import NoActions from "@myaccount/progress/NoActions";
import Actions from '@myaccount/actions';
import NewDocumentBtn from '@myaccount/property/components/newDocument';
import ActionSkeletonWithMore from "../../../../actions/actionSkeletonWithMore"
import { canShowNewDocumentBtn } from '@myaccount/property/components/newDocument/utils';

const DocumentContent = (props) => {

    const { state, services } = usePropertyActionState();

    const offerData = state?.documents || [];

    const noRecord = (!state.loading && !offerData.length);
    const propertyImage = props.property.property_detail?.thumbnail;

    // fun
    React.useEffect(() => {
        if (props?.type && (props.type === 'lettings' || props.type === 'renting'))
            services.getTenancyDocuments({property_id:props.property.property_detail.crm_id});
        else
            services.getPropertyDocuments({property_id:props.property.property_detail.crm_id});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ComponentLayout>
            {canShowNewDocumentBtn(props.property) && (<NewDocumentBtn property={props.property} />)}
            { state.loading && !offerData.length ? (
                <ActionSkeletonWithMore title />
            ) : (
                <React.Fragment>
                    {noRecord && (
                        <NoActions
                            title="There are no Documents"
                            icon="documentsIcon"
                        />
                    )}
                    {!noRecord && (
                        <Actions
                            title="Documents"
                            data={offerData}
                            showActionButton={false}
                            image={propertyImage}
                        />
                    )}
                </React.Fragment>
            )}

        </ComponentLayout>
    );
}

export default DocumentContent;
